/**
 * 
 */
export default class RestApi {
  quiz = {
    get: (code) => $app.api.get([ 'app/quizzes*', code, ]),
    see: (id) => $app.api.patch([ 'app/quizzes*/see', id, ]),
    end: (id, data) => $app.api.post([ 'app/quizzes*/end', id, ]).data(data),
  }
}
